import React, { useState, useEffect } from "react";
import { superadminApi, adminApi } from "../../services/api";
import { format } from "date-fns";
import ExcelJS from "exceljs";
import { Buffer } from "buffer";
import { downloadQRCode } from "../../utils/downloadUtils";
import {
  TrashIcon,
  DocumentArrowDownIcon,
  ArrowPathIcon,
  MagnifyingGlassIcon,
  CheckCircleIcon,
  XCircleIcon,
  PlusIcon,
  XMarkIcon,
  ArrowDownTrayIcon,
  EllipsisVerticalIcon,
  FunnelIcon,
} from "@heroicons/react/24/outline";

function EntriesManagement() {
  const [days, setDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");
  const [numberOfEntries, setNumberOfEntries] = useState("");
  const [entries, setEntries] = useState([]);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [loading, setLoading] = useState(false);
  const [generatingEntries, setGeneratingEntries] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    show: false,
    type: "",
    data: null,
  });
  const [showGenerateForm, setShowGenerateForm] = useState(false);
  const [showDaySelector, setShowDaySelector] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showUnvalidatedOnly, setShowUnvalidatedOnly] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const ENTRIES_PER_PAGE = 50;

  useEffect(() => {
    loadDays();

    const timer = setTimeout(() => {
      if (message.text) {
        setMessage({ type: "", text: "" });
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [message.text]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showOptions && !event.target.closest(".options-menu")) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showOptions]);

  useEffect(() => {
    const handlePrefixSearch = async () => {
      if (searchTerm.length === 4 && selectedDay) {
        setIsSearching(true);
        try {
          const response = await superadminApi.entries.getByPrefix(selectedDay, searchTerm);
          setSearchResults(response.data);
        } catch (error) {
          setMessage({
            type: "error",
            text: "Error al buscar entradas"
          });
        } finally {
          setIsSearching(false);
        }
      } else if (searchTerm.length < 4) {
        setSearchResults([]);
      }
    };

    handlePrefixSearch();
  }, [searchTerm, selectedDay]);

  const loadDays = async () => {
    try {
      const response = await superadminApi.classificationDays.getAll();
      setDays(response.data);
    } catch (error) {
      setMessage({
        type: "error",
        text: "Error al cargar los días de clasificación",
      });
    }
  };

  const loadUnvalidatedEntries = async () => {
    if (!selectedDay) return;

    try {
      setLoading(true);
      const response = await superadminApi.entries.getUnvalidated(selectedDay);
      setEntries(response.data);
      setShowUnvalidatedOnly(true);
    } catch (error) {
      setMessage({
        type: "error",
        text: "Error al cargar entradas sin validar"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateEntries = async () => {
    if (!selectedDay || numberOfEntries < 1) {
      setMessage({
        type: "error",
        text: "Por favor, seleccione un día y un número válido de entradas",
      });
      return;
    }

    setShowConfirmDialog({
      show: true,
      type: "generate",
      message: `¿Está seguro que desea generar ${numberOfEntries} entrada${
        numberOfEntries > 1 ? "s" : ""
      }? Esta operación puede tardar unos momentos.`,
    });
  };

  const confirmGenerateEntries = async () => {
    try {
      setGeneratingEntries(true);
      setShowConfirmDialog({ show: false, type: "", data: null });
      setShowGenerateForm(false);

      await superadminApi.entries.create(selectedDay, numberOfEntries);

      setMessage({
        type: "success",
        text: `${numberOfEntries} entradas generadas exitosamente`,
      });

      setNumberOfEntries("");
      setSelectedDay("");
    } catch (error) {
      setMessage({
        type: "error",
        text: error.response?.data?.message || "Error al generar entradas",
      });
    } finally {
      setGeneratingEntries(false);
    }
  };

  const handleGetEntries = async (resetEntries = true) => {
    if (!selectedDay) {
      setShowDaySelector(true);
      return;
    }

    setShowUnvalidatedOnly(false);
    
    try {
      setLoading(resetEntries);
      setLoadingMore(!resetEntries);

      const currentPage = resetEntries ? 1 : page;
      const offset = (currentPage - 1) * ENTRIES_PER_PAGE;

      const response = await superadminApi.entries.getAll(selectedDay, {
        limit: ENTRIES_PER_PAGE,
        offset: offset,
      });

      const { data, total } = response.data;

      if (resetEntries) {
        setEntries(data);
        setPage(2);
      } else {
        setEntries((prev) => [...prev, ...data]);
        setPage((prev) => prev + 1);
      }

      setHasMore(currentPage * ENTRIES_PER_PAGE < total);
      setShowDaySelector(false);
    } catch (error) {
      setMessage({
        type: "error",
        text: error.response?.data?.message || "Error al obtener entradas",
      });
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  const handleDeleteEntries = async (type, entry = null) => {
    setShowConfirmDialog({
      show: true,
      type: "delete",
      entryType: type,
      data: entry,
      message:
        type === "single"
          ? "¿Está seguro que desea eliminar esta entrada?"
          : "¿Está seguro que desea eliminar todas las entradas del día?",
    });
  };

  const confirmDeleteEntries = async () => {
    try {
      setLoading(true);
      setShowConfirmDialog({ show: false, type: "", data: null });

      const params = {};
      if (showConfirmDialog.entryType === "single") {
        params.id = showConfirmDialog.data.id;
      } else if (showConfirmDialog.entryType === "day") {
        params.classification_day_id = selectedDay;
      }

      await superadminApi.entries.delete(params);
      setMessage({
        type: "success",
        text: "Entradas eliminadas exitosamente",
      });
      await handleGetEntries(true);
    } catch (error) {
      setMessage({
        type: "error",
        text: error.response?.data?.message || "Error al eliminar entradas",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleValidateEntry = async (entry) => {
    setShowConfirmDialog({
      show: true,
      type: "validate",
      data: entry,
      message: `¿Está seguro que desea validar la entrada ${entry.entry_number}?`,
    });
  };

  const confirmValidateEntry = async () => {
    try {
      setLoading(true);
      setShowConfirmDialog({ show: false, type: "", data: null });

      const { data } = await adminApi.validateEntry(
        showConfirmDialog.data.entry_number
      );

      if (data?.entry) {
        setEntries((prevEntries) =>
          prevEntries.map((entry) =>
            entry.entry_number === showConfirmDialog.data.entry_number
              ? {
                  ...entry,
                  validated: data.entry.validated,
                  validated_by: data.entry.validated_by,
                }
              : entry
          )
        );

        setMessage({
          type: "success",
          text: data.message || "Entrada validada exitosamente",
        });
      }
    } catch (error) {
      setMessage({
        type: "error",
        text: error.response?.data?.message || "Error al validar entrada",
      });
    } finally {
      setLoading(false);
    }
  };

  const copyEntryNumber = async (entryNumber) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(entryNumber);
        setMessage({
          type: "success",
          text: "Número de entrada copiado al portapapeles",
        });
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = entryNumber;
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          document.execCommand("copy");
          textArea.remove();
          setMessage({
            type: "success",
            text: "Número de entrada copiado al portapapeles",
          });
        } catch (err) {
          console.error("Error al copiar:", err);
          textArea.remove();
          setMessage({
            type: "error",
            text: "Error al copiar el número de entrada",
          });
        }
      }
    } catch (err) {
      console.error("Error al copiar:", err);
      setMessage({
        type: "error",
        text: "Error al copiar el número de entrada",
      });
    }
  };

  const generateExcel = async () => {
    if (!selectedDay) return;

    try {
      setLoading(true);

      const response = await superadminApi.entries.getAll(selectedDay, {
        limit: 999999,
        offset: 0,
      });

      const { data: allEntries } = response.data;

      if (allEntries.length === 0) {
        setMessage({
          type: "error",
          text: "No hay entradas para exportar",
        });
        return;
      }

      const selectedDayInfo = days.find(
        (day) => day.id === parseInt(selectedDay)
      );
      const formattedDate = selectedDayInfo
        ? format(new Date(selectedDayInfo.date), "dd/MM/yyyy")
        : "";

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Entradas");

      worksheet.columns = [
        { header: "Fecha", key: "fecha", width: 15 },
        { header: "Número de Entrada", key: "numero", width: 20 },
        { header: "QR", key: "qr", width: 30 },
      ];

      for (const entry of allEntries) {
        const row = worksheet.addRow({
          fecha: formattedDate,
          numero: entry.entry_number,
        });

        if (entry.qr_code_base64) {
          const base64Data = entry.qr_code_base64.split(",")[1];
          const imageBuffer = Buffer.from(base64Data, "base64");

          const imageId = workbook.addImage({
            buffer: imageBuffer,
            extension: "png",
          });

          worksheet.addImage(imageId, {
            tl: { col: 2, row: row.number - 1 },
            ext: { width: 100, height: 100 },
            editAs: "oneCell",
          });

          row.height = 75;
        }
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `entradas_${formattedDate.replace(/\//g, "-")}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);

      setMessage({
        type: "success",
        text: `Se han exportado ${allEntries.length} entradas exitosamente`,
      });
    } catch (error) {
      setMessage({
        type: "error",
        text: "Error al generar el archivo Excel",
      });
      console.error("Error generando Excel:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = (e) => {
    const element = e.target;
    if (
      element.scrollHeight - element.scrollTop === element.clientHeight &&
      hasMore &&
      !loadingMore &&
      !loading
    ) {
      handleGetEntries(false);
    }
  };

  const handleDownloadQR = (entry) => {
    try {
      downloadQRCode(entry.qr_code_base64, entry.entry_number);
      setMessage({
        type: "success",
        text: "QR descargado exitosamente",
      });
    } catch (error) {
      setMessage({
        type: "error",
        text: "Error al descargar el QR",
      });
      console.error("Error downloading QR:", error);
    }
  };

  const getFilteredEntries = () => {
    if (searchTerm.length >= 4 && searchResults.length > 0) {
      return searchResults;
    }
    
    if (showUnvalidatedOnly) {
      return entries.filter(entry => !entry.validated);
    }
    
    return entries.filter(entry => 
      searchTerm.length < 4 ? entry.entry_number.toLowerCase().includes(searchTerm.toLowerCase()) : true
    );
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-4 sm:p-6">
      {(loading || generatingEntries) && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-8 flex flex-col items-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
            <p className="mt-4 text-gray-700 dark:text-gray-300">
              {generatingEntries ? "Generando entradas..." : "Cargando..."}
            </p>
          </div>
        </div>
      )}

      <div className="relative mb-6">
        <div className="flex justify-between items-center flex-wrap gap-4">
          <div className="relative inline-block text-left options-menu">
            <button
              type="button"
              className="inline-flex items-center justify-center px-4 py-2 rounded-lg bg-indigo-600 hover:bg-indigo-700 text-white shadow-sm transition-colors duration-200"
              onClick={() => setShowOptions(!showOptions)}
            >
              <span className="mr-2">Opciones</span>
              <EllipsisVerticalIcon className="h-5 w-5" />
            </button>

            {showOptions && (
              <div className="origin-top-left absolute left-0 mt-2 w-72 md:w-56 rounded-lg shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 dark:divide-gray-700 z-50">
                <div className="py-2">
                  <button
                    onClick={() => {
                      setShowGenerateForm(true);
                      setShowOptions(false);
                    }}
                    className="group flex items-center w-full px-4 py-3 text-sm text-gray-700 dark:text-gray-200 hover:bg-indigo-50 dark:hover:bg-indigo-900/30"
                  >
                    <PlusIcon className="mr-3 h-5 w-5 text-indigo-500" />
                    <span className="font-medium">Generar Entradas</span>
                  </button>
                  <button
                    onClick={() => {
                      setShowDaySelector(true);
                      setShowOptions(false);
                    }}
                    className="group flex items-center w-full px-4 py-3 text-sm text-gray-700 dark:text-gray-200 hover:bg-indigo-50 dark:hover:bg-indigo-900/30"
                  >
                    <ArrowPathIcon className="mr-3 h-5 w-5 text-indigo-500" />
                    <span className="font-medium">Obtener Entradas</span>
                  </button>
                </div>

                {entries.length > 0 && (
                  <div className="py-2">
                    <button
                      onClick={() => {
                        generateExcel();
                        setShowOptions(false);
                      }}
                      className="group flex items-center w-full px-4 py-3 text-sm text-gray-700 dark:text-gray-200 hover:bg-indigo-50 dark:hover:bg-indigo-900/30"
                    >
                      <DocumentArrowDownIcon className="mr-3 h-5 w-5 text-indigo-500" />
                      <span className="font-medium">Exportar a Excel</span>
                    </button>
                    <button
                      onClick={() => {
                        handleDeleteEntries("day");
                        setShowOptions(false);
                      }}
                      className="group flex items-center w-full px-4 py-3 text-sm text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20"
                    >
                      <TrashIcon className="mr-3 h-5 w-5 text-red-500" />
                      <span className="font-medium">Eliminar Todas</span>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>

          {entries.length > 0 && (
            <div className="flex items-center gap-4 flex-grow md:flex-grow-0">
              <div className="relative flex-grow md:w-64 md:flex-grow-0">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  placeholder="Buscar por número..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg leading-5 bg-white dark:bg-gray-700 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                />
                {isSearching && (
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-indigo-600"></div>
                  </div>
                )}
              </div>

              <button
                onClick={() => {
                  if (showUnvalidatedOnly) {
                    handleGetEntries(true);
                  } else {
                    loadUnvalidatedEntries();
                  }
                }}
                className={`inline-flex items-center px-3 py-2 rounded-lg ${
                  showUnvalidatedOnly
                    ? "bg-indigo-100 text-indigo-700 dark:bg-indigo-900 dark:text-indigo-200"
                    : "bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-200"
                } hover:bg-opacity-80 transition-colors duration-200`}
              >
                <FunnelIcon className="h-5 w-5 mr-2" />
                {showUnvalidatedOnly ? "Ver todas" : "Sin validar"}
              </button>
            </div>
          )}
        </div>
      </div>

      {showGenerateForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full relative">
            <button
              onClick={() => setShowGenerateForm(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>

            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
              Generar Entradas
            </h3>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Día de Clasificación
                </label>
                <select
                  value={selectedDay}
                  onChange={(e) => setSelectedDay(e.target.value)}
                  className="form-select w-full"
                  disabled={loading || generatingEntries}
                >
                  <option value="">Seleccione un día</option>
                  {days.map((day) => (
                    <option key={day.id} value={day.id}>
                      {format(new Date(day.date), "dd/MM/yyyy")} -{" "}
                      {day.description}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Número de Entradas
                </label>
                <input
                  type="number"
                  min="1"
                  value={numberOfEntries}
                  onChange={(e) =>
                    setNumberOfEntries(parseInt(e.target.value) || "")
                  }
                  className="form-input w-full"
                  disabled={loading || generatingEntries}
                />
              </div>

              <div className="flex justify-end gap-4 mt-6">
                <button
                  onClick={() => setShowGenerateForm(false)}
                  className="px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
                  disabled={loading || generatingEntries}
                >
                  Cancelar
                </button>
                <button
                  onClick={handleGenerateEntries}
                  disabled={
                    loading ||
                    generatingEntries ||
                    !selectedDay ||
                    !numberOfEntries
                  }
                  className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Generar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showDaySelector && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full relative">
            <button
              onClick={() => setShowDaySelector(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>

            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
              Seleccionar Día
            </h3>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Día de Clasificación
                </label>
                <select
                  value={selectedDay}
                  onChange={(e) => setSelectedDay(e.target.value)}
                  className="form-select w-full"
                  disabled={loading || generatingEntries}
                >
                  <option value="">Seleccione un día</option>
                  {days.map((day) => (
                    <option key={day.id} value={day.id}>
                      {format(new Date(day.date), "dd/MM/yyyy")} -{" "}
                      {day.description}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex justify-end gap-4 mt-6">
                <button
                  onClick={() => setShowDaySelector(false)}
                  className="px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
                  disabled={loading || generatingEntries}
                >
                  Cancelar
                </button>
                <button
                  onClick={() => handleGetEntries(true)}
                  disabled={loading || generatingEntries || !selectedDay}
                  className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Obtener
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {entries.length > 0 && (
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden border border-gray-200 dark:border-gray-700 sm:rounded-lg">
              <div
                className="max-h-[600px] overflow-y-auto"
                onScroll={handleScroll}
              >
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-gray-50 dark:bg-gray-700 sticky top-0 z-10">
                    <tr>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                      >
                        Número
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                      >
                        Estado
                      </th>
                      <th
                        scope="col"
                        className="hidden md:table-cell px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                      >
                        QR
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                      >
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                    {getFilteredEntries().map((entry) => (
                      <tr
                        key={`${entry.id}-${entry.entry_number}`}
                        className="hover:bg-gray-50 dark:hover:bg-gray-700"
                      >
                        <td className="px-4 py-4">
                          <button
                            onClick={() => copyEntryNumber(entry.entry_number)}
                            className="text-sm text-gray-900 dark:text-white hover:text-indigo-600 dark:hover:text-indigo-400 font-medium"
                            title="Copiar número de entrada"
                            disabled={loading || generatingEntries}
                          >
                            {entry.entry_number}
                          </button>
                        </td>
                        <td className="px-4 py-4">
                          <button
                            onClick={() =>
                              !entry.validated && handleValidateEntry(entry)
                            }
                            disabled={
                              entry.validated || loading || generatingEntries
                            }
                            className={`inline-flex items-center px-2.5 py-1.5 rounded-full text-xs font-medium
                              ${
                                entry.validated
                                  ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"
                                  : "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200 cursor-pointer hover:bg-yellow-200 dark:hover:bg-yellow-800"
                              } ${
                              (loading || generatingEntries) &&
                              "opacity-50 cursor-not-allowed"
                            }`}
                          >
                            {entry.validated ? (
                              <>
                                <CheckCircleIcon className="h-4 w-4 mr-1" />
                                <span className="hidden sm:inline">
                                  Vali dada por:&nbsp;
                                </span>
                                {entry.validated_by || "Sistema"}
                              </>
                            ) : (
                              <>
                                <XCircleIcon className="h-4 w-4 mr-1" />
                                <span className="hidden sm:inline">
                                  No&nbsp;
                                </span>
                                Validada
                              </>
                            )}
                          </button>
                        </td>
                        <td className="hidden md:table-cell px-4 py-4">
                          <img
                            src={entry.qr_code_base64}
                            alt={`QR para entrada ${entry.entry_number}`}
                            className="h-16 w-16"
                          />
                        </td>
                        <td className="px-4 py-4 text-right">
                          <div className="flex justify-end space-x-2">
                            <button
                              onClick={() => handleDownloadQR(entry)}
                              className="text-blue-600 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300 disabled:opacity-50 disabled:cursor-not-allowed"
                              disabled={loading || generatingEntries}
                              title="Descargar QR"
                            >
                              <ArrowDownTrayIcon className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() =>
                                handleDeleteEntries("single", entry)
                              }
                              className="text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-300 disabled:opacity-50 disabled:cursor-not-allowed"
                              disabled={loading || generatingEntries}
                              title="Eliminar entrada"
                            >
                              <TrashIcon className="h-5 w-5" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {loadingMore && (
                  <div className="p-4 flex justify-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {showConfirmDialog.show && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-sm w-full">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
              Confirmar Acción
            </h3>
            <p className="text-gray-500 dark:text-gray-400 mb-6">
              {showConfirmDialog.message}
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() =>
                  setShowConfirmDialog({ show: false, type: "", data: null })
                }
                className="px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
                disabled={loading || generatingEntries}
              >
                Cancelar
              </button>
              <button
                onClick={() => {
                  switch (showConfirmDialog.type) {
                    case "delete":
                      confirmDeleteEntries();
                      break;
                    case "generate":
                      confirmGenerateEntries();
                      break;
                    case "validate":
                      confirmValidateEntry();
                      break;
                    default:
                      break;
                  }
                }}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={loading || generatingEntries}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}

      {message.text && (
        <div
          className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 rounded-md shadow-lg z-50 ${
            message.type === "success"
              ? "bg-green-50 text-green-800 dark:bg-green-900 dark:text-green-200"
              : "bg-red-50 text-red-800 dark:bg-red-900 dark:text-red-200"
          }`}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default EntriesManagement;