import axios from "axios";
import { API_CONFIG } from "../config/api.config";

const api = axios.create({
  baseURL: API_CONFIG.BASE_URL,
  withCredentials: true,
});

const CACHE_DURATION = 8 * 3600000; // 1 hora en milisegundos

const CACHE_KEYS = {
  USERS: 'users_data',
  ROLES: 'roles_data',
  CONTESTANTS: 'contestants_data',
  CONTESTANTS_TYPES: 'contestants_types_data',
  CLASSIFICATION_DAYS: 'classification_days_data',
  CONTEST_STATUS: 'contest_status_data',
  CONTESTANTS_TODAY: 'contestants_today'
};

// Función para limpiar toda la caché
const clearAllCache = () => {
  Object.values(CACHE_KEYS).forEach(key => localStorage.removeItem(key));
};

// Función para obtener datos de la caché
const getCachedData = (key) => {
  const cachedData = localStorage.getItem(key);
  if (cachedData) {
    const { data, timestamp } = JSON.parse(cachedData);
    if (Date.now() - timestamp < CACHE_DURATION) {
      return data;
    }
    localStorage.removeItem(key);
  }
  return null;
};

// Función para guardar datos en caché
const setCacheData = (key, data) => {
  localStorage.setItem(
    key,
    JSON.stringify({
      data,
      timestamp: Date.now(),
    })
  );
};

// Función para limpiar caché específica
const clearCache = (key) => {
  localStorage.removeItem(key);
};

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("role");
      localStorage.removeItem("authenticated");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export const contestApi = {
  getContestantsToday: async () => {
    const cachedData = getCachedData(CACHE_KEYS.CONTESTANTS_TODAY);
    if (cachedData) return cachedData;

    const response = await api.get(API_CONFIG.ENDPOINTS.CONTEST.CONTESTANTS.GET_TODAY);
    setCacheData(CACHE_KEYS.CONTESTANTS_TODAY, response.data);
    return response.data;
  },

  createVotes: async (entryNumber, votes) => {
    const response = await api.post(API_CONFIG.ENDPOINTS.VOTE.CREATE, {
      entry_number: entryNumber,
      votes,
    });
    clearCache(CACHE_KEYS.CONTESTANTS_TODAY);
    return response;
  },
};

export const adminApi = {
  validateEntry: async (entryNumber) => {
    return await api.post(API_CONFIG.ENDPOINTS.CONTEST.ENTRIES.VALIDATE, {
      entry_number: entryNumber,
    });
  },
};

export const superadminApi = {
  clearAllCache,
  users: {
    login: async (credentials) => {
      return await api.post(API_CONFIG.ENDPOINTS.AUTH.LOGIN, credentials);
    },
    verify: async () => {
      return await api.get(API_CONFIG.ENDPOINTS.AUTH.CHECK);
    },
    register: async (userData) => {
      const response = await api.post(API_CONFIG.ENDPOINTS.AUTH.REGISTER, userData);
      clearCache(CACHE_KEYS.USERS);
      return response;
    },
    updatePassword: async (userId, newPassword) => {
      const response = await api.patch(API_CONFIG.ENDPOINTS.AUTH.UPDATE_PASSWORD, {
        userId,
        newPassword,
      });
      clearCache(CACHE_KEYS.USERS);
      return response;
    },
    updateUserRole: async (userId, roleId) => {
      const response = await api.patch(API_CONFIG.ENDPOINTS.AUTH.UPDATE_USER_ROLE, {
        userId,
        newRoleId: roleId
      });
      clearCache(CACHE_KEYS.USERS);
      return response;
    },
    updateRole: async (roleId, roleName) => {
      const response = await api.patch(API_CONFIG.ENDPOINTS.AUTH.UPDATE_ROLE, {
        roleId,
        roleName
      });
      clearCache(CACHE_KEYS.ROLES);
      return response;
    },
    getUsers: async () => {
      const cachedData = getCachedData(CACHE_KEYS.USERS);
      if (cachedData) return { data: cachedData };

      const response = await api.get(API_CONFIG.ENDPOINTS.AUTH.GET_USERS);
      setCacheData(CACHE_KEYS.USERS, response.data);
      return response;
    },
    getRoles: async () => {
      const cachedData = getCachedData(CACHE_KEYS.ROLES);
      if (cachedData) return { data: cachedData };

      const response = await api.get(API_CONFIG.ENDPOINTS.AUTH.GET_ROLES);
      setCacheData(CACHE_KEYS.ROLES, response.data);
      return response;
    },
    deleteUser: async (id) => {
      const response = await api.delete(API_CONFIG.ENDPOINTS.AUTH.DELETE_USER, {
        data: { userId: id },
      });
      clearCache(CACHE_KEYS.USERS);
      return response;
    },
    deleteRole: async (id) => {
      const response = await api.delete(API_CONFIG.ENDPOINTS.AUTH.DELETE_ROLE, {
        data: { roleId: id },
      });
      clearCache(CACHE_KEYS.ROLES);
      return response;
    },
    createRole: async (roleName) => {
      const response = await api.post(API_CONFIG.ENDPOINTS.AUTH.CREATE_ROLE, {
        roleName
      });
      clearCache(CACHE_KEYS.ROLES);
      return response;
    },
  },
  contestants: {
    getAll: async () => {
      const cachedData = getCachedData(CACHE_KEYS.CONTESTANTS);
      if (cachedData) return { data: cachedData };

      const response = await api.get(API_CONFIG.ENDPOINTS.CONTEST.CONTESTANTS.GET_ALL);
      setCacheData(CACHE_KEYS.CONTESTANTS, response.data);
      return response;
    },
    create: async (contestantData) => {
      const response = await api.post(API_CONFIG.ENDPOINTS.CONTEST.CONTESTANTS.CREATE, contestantData);
      clearCache(CACHE_KEYS.CONTESTANTS);
      return response;
    },
    update: async (typeData) => {
      const response = await api.patch(API_CONFIG.ENDPOINTS.CONTEST.CONTESTANTS.UPDATE, typeData);
      clearCache(CACHE_KEYS.CONTESTANTS);
      return response;
    },
    delete: async (id) => {
      const response = await api.delete(API_CONFIG.ENDPOINTS.CONTEST.CONTESTANTS.DELETE, {
        data: { id },
      });
      clearCache(CACHE_KEYS.CONTESTANTS);
      return response;
    },
  },
  votes: {
    getResults: async (classificationDayId) => {
      return await api.get(API_CONFIG.ENDPOINTS.VOTE.RESULTS, {
        params: { classification_day_id: classificationDayId }
      });
    },
    getDailyStats: async (classificationDayId) => {
      return await api.get(API_CONFIG.ENDPOINTS.VOTE.DAILY_STATUS, {
        params: { classification_day_id: classificationDayId }
      });
    }
  },  
  contestantTypes: {
    getAll: async () => {
      const cachedData = getCachedData(CACHE_KEYS.CONTESTANTS_TYPES);
      if (cachedData) return { data: cachedData };

      const response = await api.get(API_CONFIG.ENDPOINTS.CONTEST.TYPES.GET_ALL);
      setCacheData(CACHE_KEYS.CONTESTANTS_TYPES, response.data);
      return response;
    },
    create: async (typeData) => {
      const response = await api.post(API_CONFIG.ENDPOINTS.CONTEST.TYPES.CREATE, typeData);
      clearCache(CACHE_KEYS.CONTESTANTS_TYPES);
      return response;
    },
    update: async (typeData) => {
      const response = await api.patch(API_CONFIG.ENDPOINTS.CONTEST.TYPES.UPDATE, typeData);
      clearCache(CACHE_KEYS.CONTESTANTS_TYPES);
      return response;
    },
    delete: async (id) => {
      const response = await api.delete(API_CONFIG.ENDPOINTS.CONTEST.TYPES.DELETE, {
        data: { id },
      });
      clearCache(CACHE_KEYS.CONTESTANTS_TYPES);
      return response;
    },
  },
  classificationDays: {
    getAll: async () => {
      const cachedData = getCachedData(CACHE_KEYS.CLASSIFICATION_DAYS);
      if (cachedData) return { data: cachedData };

      const response = await api.get(API_CONFIG.ENDPOINTS.CONTEST.DAYS.GET_ALL);
      setCacheData(CACHE_KEYS.CLASSIFICATION_DAYS, response.data);
      return response;
    },
    create: async (dayData) => {
      const response = await api.post(API_CONFIG.ENDPOINTS.CONTEST.DAYS.CREATE, dayData);
      clearCache(CACHE_KEYS.CLASSIFICATION_DAYS);
      return response;
    },
    update: async (id, dayData) => {
      const response = await api.patch(API_CONFIG.ENDPOINTS.CONTEST.DAYS.UPDATE, {
        id,
        ...dayData
      });
      clearCache(CACHE_KEYS.CLASSIFICATION_DAYS);
      return response;
    },
    delete: async (id) => {
      const response = await api.delete(API_CONFIG.ENDPOINTS.CONTEST.DAYS.DELETE, {
        data: { id },
      });
      clearCache(CACHE_KEYS.CLASSIFICATION_DAYS);
      return response;
    },
  },
  contestStatus: {
    get: async () => {
      const cachedData = getCachedData(CACHE_KEYS.CONTEST_STATUS);
      if (cachedData) return { data: cachedData };

      const response = await api.get(API_CONFIG.ENDPOINTS.CONTEST.STATUS.GET);
      setCacheData(CACHE_KEYS.CONTEST_STATUS, response.data);
      return response;
    },
    update: async (classificationDay) => {
      const response = await api.post(API_CONFIG.ENDPOINTS.CONTEST.STATUS.UPDATE, {
        classification_day_id: classificationDay,
      });
      clearCache(CACHE_KEYS.CONTEST_STATUS);
      return response;
    },
    delete: async (id) => {
      return await api.delete(API_CONFIG.ENDPOINTS.CONTEST.STATUS.DELETE, {
        data: { id },
      });
    },
  },
  entries: {
    getAll: async (classificationDayId, options = {}) => {
      const { limit = 50, offset = 0 } = options;
      return await api.get(API_CONFIG.ENDPOINTS.CONTEST.ENTRIES.GET_ALL, {
        params: {
          classification_day_id: classificationDayId,
          limit,
          offset
        },
      });
    },
    create: async (classificationDayId, numberOfEntries) => {
      return await api.post(API_CONFIG.ENDPOINTS.CONTEST.ENTRIES.CREATE, {
        classification_day_id: classificationDayId,
        number_of_entries: numberOfEntries,
      });
    },
    delete: async (params = {}) => {
      return await api.delete(API_CONFIG.ENDPOINTS.CONTEST.ENTRIES.DELETE, {
        data: params,
      });
    },
    getByPrefix: async (classificationDayId, prefixOfEntrie) => {
      return await api.get(API_CONFIG.ENDPOINTS.CONTEST.ENTRIES.PREFIX, {
        params: {
          classification_day_id: classificationDayId,
          prefix: prefixOfEntrie,
        }
      });
    },
    getUnvalidated: async (classificationDayId) => {
      return await api.get(API_CONFIG.ENDPOINTS.CONTEST.ENTRIES.UNVALIDATED, {
        params: {
          classification_day_id: classificationDayId
        }
      });
    }
    
  },
  cloudfront: {
    clearCache: async (params) => {
      return await api.post(API_CONFIG.ENDPOINTS.CLOUDFRONT.CLEAR_CACHE, params);
    },
  },
};

export default api;
