import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

export const generateResultsPDF = (results, dayInfo) => {
  const doc = new jsPDF();
  
  // Configuración de fuentes
  doc.setFont("helvetica", "bold");
  doc.setFontSize(20);
  
  // Título
  doc.text("Resultados de Votación", 105, 20, { align: "center" });
  
  // Información del día
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  const formattedDate = format(new Date(dayInfo.date), "EEEE d 'de' MMMM 'de' yyyy", { locale: es });
  doc.text(`Día: ${formattedDate}`, 20, 35);
  
  // Estadísticas de participación
  doc.setFontSize(14);
  doc.setFont("helvetica", "bold");
  doc.text("Estadísticas de Participación", 20, 65);
  
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  doc.text(`Total de Entradas Generadas: ${dayInfo.totalEntries}`, 20, 80);
  doc.text(`Total de Votos Registrados: ${dayInfo.groupedVotes}`, 20, 90);
  doc.text(`Porcentaje de Participación: ${dayInfo.participationRate}%`, 20, 100);
  
  // Fecha y hora de generación
  const now = new Date();
  doc.setFontSize(10);
  doc.text(`Generado el: ${format(now, "d/MM/yyyy HH:mm:ss")}`, 20, 115);
  
  // Tabla de resultados
  const tableData = results.map((result, index) => [
    `${index + 1}º`,
    result.contestant_name,
    result.contestants_type,
    result.total_votes.toString()
  ]);
  
  doc.autoTable({
    startY: 125,
    head: [['Posición', 'Concursante', 'Categoría', 'Votos']],
    body: tableData,
    theme: 'grid',
    headStyles: {
      fillColor: [63, 81, 181],
      textColor: 255,
      fontSize: 12,
      fontStyle: 'bold',
      halign: 'center'
    },
    bodyStyles: {
      fontSize: 11,
      halign: 'center'
    },
    columnStyles: {
      0: { cellWidth: 30 },
      1: { cellWidth: 80 },
      2: { cellWidth: 40 },
      3: { cellWidth: 30 }
    },
    alternateRowStyles: {
      fillColor: [245, 245, 245]
    }
  });
  
  // Pie de página
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(10);
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text(
      `Página ${i} de ${pageCount}`,
      doc.internal.pageSize.width / 2,
      doc.internal.pageSize.height - 10,
      { align: "center" }
    );
  }
  
  // Guardar el PDF
  doc.save(`resultados_votacion_${format(new Date(dayInfo.date), 'yyyy-MM-dd')}.pdf`);
};